import React, { useState } from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({langToggle}) => {
    return (
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          name="toggleSwitch"
          id="toggleSwitch"
          onClick={langToggle}
        />
        <label className="toggle-switch-label" htmlFor="toggleSwitch">
          <span className="toggle-switch-inner" />
          <span className="toggle-switch-switch" />
        </label>
      </div>
    );
}

export default ToggleSwitch;