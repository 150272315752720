import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

export const Nav = styled.nav`
    background: #f6f6f6;
    width: 100%;
    /*background: ${({scrollNav}) => (scrollNav ? '#000' : 'transparent' )}*/
    height: 100px;
    margin-top: -125px; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`
;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1350px;
`
;

export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size= 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;

    @media screen and (max-width: 768px) {
        margin-left: 1px;
    }
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        margin-top: 35px;
        font-size: 1.8rem;
        cursor: pointer;
        
    }
`;

export const ImgLogo = styled.img`
    height: 80px;
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;
    margin-top: 50px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavItem = styled.li`
    height: 50px;
`;

export const NavLinks = styled(LinkS)`
     color: #000;
     display: flex;
     align-items: center;
     text-decoration: none;
     /*padding: 0 1rem;*/
     padding: 0 2rem;
     height: 100%;
     cursor: pointer;

     &.active {
         border-bottom: 3px solid #ff6633;
     }
`;

export const NavBtn = styled.nav`
     display: flex;
     align-items: center;

     @media screen and (max-width: 768px){
         display: none;
     }

     @media screen and (max-width: 960px) {
        display: none;
    }
`;

export const NavColumn1 = styled.div`
    /*margin-bottom: 15px;
    padding: 0 15px;*/

    position: absolute;
    @media screen and (max-width: 768px){
        display: none;
    }

    @media screen and (max-width: 1150px) {
       display: none;
   }
`;

export const NavColumn2 = styled.div`
    padding-left: 10px;
`;

export const NavColumn3 = styled.div`
    @media screen and (min-width: 768px) {
        padding-left: 20px;
        padding-top: 50px;
    }

    /*@media screen and (max-width: 768px) {
        padding-top: 5px;
    }*/

    @media screen and (max-width: 768px){
        display: none;
    }

    @media screen and (max-width: 960px) {
       display: none;
   }
`;


export const NavSwitch = styled.nav`
     /*display: flex;
     align-items: center;*/
     position: relative;
     display: flex;
`;


export const NavBtnLink = styled(LinkS)`
     border-radius: 50px;
     /*background: #ff6633;*/
     background: #ff6f7f;
     white-space: nowrap;
     padding: 10px 22px;
     color: #010606;
     font-size: 16px;
     outline: none;
     border: none;
     cursor: pointer;
     transition: all 0.2s ease-in-out;
     text-decoration: none;

     &:hover {
         transition: all 0.2s ease-in-out;
         background: #fff;
         color: #010606;
     }
`;