import React, {useState, useEffect} from 'react';
import { Button } from '../ButtonElements'; 
import { IntroWrapper, InfoContainer, InfoWrapperSmaller, InfoWrapper, InfoRow, Column1, Column2, Column3, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img, ImageLevensPad } from './aboutElements';
import Intro from '../Intro/intro';

const About = ({isToggled, slider,lightBg, id, imgStart, topLineEN, topLineNL, lightText, headlineEN, headlineNL, darkText, descriptionEN, descriptionNL, img, alt, dark, dark2, primary }) => {
    
    const [showMore, setShowMore] = useState(true);

    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
          width: undefined,
          height: undefined,
        });
      
        useEffect(() => {
          // only execute all the code below in client side
          if (typeof window !== 'undefined') {
            // Handler to call on window resize
            function handleResize() {
              // Set window width/height to state
              setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
              });
            }
          
            // Add event listener
            window.addEventListener("resize", handleResize);
           
            // Call handler right away so state gets updated with initial window size
            handleResize();
          
            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
          }
        }, []); // Empty array ensures that effect is only run on mount
        return windowSize;
      }
    
      const size = useWindowSize();
    

    return (
        <>
            {showMore? 
            <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapperSmaller>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{isToggled? topLineEN : topLineNL}</TopLine>
                                <Heading lightText={lightText}>{isToggled? headlineEN : headlineNL}</Heading>
                                <Subtitle darkText={darkText}>{isToggled? descriptionEN : descriptionNL }</Subtitle>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                        <IntroWrapper>
                        <Intro introText={isToggled? 'Welcome to the website of' : 'Welkom op de website van'} 
                    nameText = 'VermoesenIT'
                    itema = {isToggled? 'Data Engineer' : 'Data ingenieur'}
                    itemb = {isToggled? 'Electrical Engineer' : 'Burgerlijk ingenieur elektrotechniek'} 
                    itemc = {isToggled? 'Full stack web enthusiastic' : 'Full stack web fanatist'}
                    itemd = {isToggled? 'Gets Energy from being creative' : 'Krijgt energie van een creatief proces'} 
                    iteme = {isToggled? 'Loves to create' : 'Houdt van creëren'} />
                    </IntroWrapper>
                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>
                        </Column2>
                    </InfoRow>
                    {size.width > 768 & !isToggled?
                    <ImageLevensPad src={require('../../images/levenspad_NL_pc2.png').default} />
                    : size.width <= 768 & !isToggled?
                    <ImageLevensPad src={require('../../images/levenspad_NL_gsm2.png').default} />
                    : size.width > 768 & isToggled?
                    <ImageLevensPad src={require('../../images/levenspad_EN_pc2.png').default} />
                    : size.width <= 768 & isToggled?
                    <ImageLevensPad src={require('../../images/levenspad_EN_gsm2.png').default} />
                    : ''
                    }
                </InfoWrapperSmaller>
            </InfoContainer>
            
             </> :
             <InfoContainer lightBg={lightBg} id={id}>
             <InfoWrapper>
                 <InfoRow imgStart={imgStart}>
                     <Column1>
                         <TextWrapper>
                             <TopLine>{isToggled? topLineEN : topLineNL}</TopLine>
                             <Heading lightText={lightText}>{isToggled? headlineEN : headlineNL}</Heading>
                             <Subtitle darkText={darkText}>{isToggled? descriptionEN : descriptionNL }</Subtitle>
                         </TextWrapper>
                     </Column1>
                     <Column2>
                     <ImgWrap>
                         <Img src={img} alt={alt}/>
                     </ImgWrap>
                     </Column2>
                 </InfoRow>
             </InfoWrapper>
         </InfoContainer> 
            }
        </>
    );
};

export default About;

/* 
import React, {useState} from 'react';
import { Button } from '../ButtonElements'; 
import { InfoContainer, InfoWrapperSmaller, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './aboutElements';
import Intro from '../Intro/intro';

const About = ({isToggled, slider,lightBg, id, imgStart, topLineEN, topLineNL, lightText, headlineEN, headlineNL, darkText, descriptionEN, descriptionNL, img, alt, dark, dark2, primary }) => {
    
    const [showMore, setShowMore] = useState(true);

    return (
        <>
            {showMore? 
            <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapperSmaller>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{isToggled? topLineEN : topLineNL}</TopLine>
                                <Heading lightText={lightText}>{isToggled? headlineEN : headlineNL}</Heading>
                                <Subtitle darkText={darkText}>{isToggled? descriptionEN : descriptionNL }</Subtitle>
                                <BtnWrap>
                                    <Button 
                                        to='home'
                                        onClick={() => setShowMore(!showMore)}
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}>
                                        {showMore && isToggled ? "Show less" 
                                        :showMore && !isToggled? "Toon minder"
                                        :!showMore && isToggled? "Read more"
                                        : "Lees meer"}</Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapperSmaller>
            </InfoContainer>
             <Intro/>
             
             </> :
             <InfoContainer lightBg={lightBg} id={id}>
             <InfoWrapper>
                 <InfoRow imgStart={imgStart}>
                     <Column1>
                         <TextWrapper>
                             <TopLine>{isToggled? topLineEN : topLineNL}</TopLine>
                             <Heading lightText={lightText}>{isToggled? headlineEN : headlineNL}</Heading>
                             <Subtitle darkText={darkText}>{isToggled? descriptionEN : descriptionNL }</Subtitle>
                             <BtnWrap>
                                 <Button 
                                     to='home'
                                     onClick={() => setShowMore(!showMore)}
                                     smooth={true}
                                     duration={500}
                                     spy={true}
                                     exact="true"
                                     offset={-80}
                                     primary={primary ? 1 : 0}
                                     dark={dark ? 1 : 0}
                                     dark2={dark2 ? 1 : 0}>
                                     {showMore && isToggled ? "Show less" 
                                        :showMore && !isToggled? "Toon minder"
                                        :!showMore && isToggled? "Read more"
                                        : "Lees meer"}</Button>
                             </BtnWrap>
                         </TextWrapper>
                     </Column1>
                     <Column2>
                     <ImgWrap>
                         <Img src={img} alt={alt}/>
                     </ImgWrap>
                     </Column2>
                 </InfoRow>
             </InfoWrapper>
         </InfoContainer> 
            }
        </>
    );
};

export default About; */
