import React, { useState } from 'react';
import { SliderData } from './SliderData';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import './ImageSliderElements.css';

const ImageSlider = ({ slides, isToggled }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <section className='slider' id='imageslider'>
      <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
      <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} />
      {SliderData.map((slide, index) => {
        return (
          <div
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          >
            {index === current && (
              <div className="container">
              <img src={slide.image} alt={slide.altname} className='image' />
              <p className="little-description">
              {isToggled? slide.descriptionEN : slide.descriptionNL}
              {slide.pablo? <a className='link-to-pablo' rel='external' href='https://www.pablothebarber.be' hrefLang='en'> www.pablothebarber.be</a> : ' '}
              {slide.ann? <a className='link-to-pablo' rel='external' href='https://www.ann-art.be' hrefLang='nl'> www.ann-art.be</a> : ' '}
              </p>
              </div>
            )}
          </div>
        );
      })}
    </section>
  );
};

export default ImageSlider;

/*
<div className = "head-text">
              <div className = "head-image">
              <img src={slide.image} alt='travel image' className='image' />
              </div>
              <div class='text-on-image'>
             <h3> You attract what you are, not what you want </h3>
              </div>
              </div>
              */