import styled from 'styled-components';

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#010606')}; 
    height: 125vh;
    padding-top: 20px;
    padding-bottom: 20px;

    @media screen and (max-width: 768px) {
        padding: 100px 0;
        height: 230vh;
    }
`;

export const InfoWrapperPortfolio = styled.div`
    display: grid;
    z-index: 1;
    /*height: 860px;*/
    height: 100vh;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    /*padding: 0 24px;*/
    justify-content: center;

    /*@media screen and (max-width: 768px){
       
    }*/
`;

export const InfoWrapper= styled.div`
    display: grid;
    z-index: 1;
    /*height: 860px;*/
    height: 100vh;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    /*padding: 0 24px;*/
    justify-content: center;

    /*@media screen and (max-width: 768px){
        height: 800px;
    }*/
`;


export const InfoWrapperSmaller= styled.div`
    display: grid;
    z-index: 1;
    /*height: 800px;*/
    height: 100vh;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    /*padding: 0 24px;*/
    justify-content: center;

    /*@media screen and (max-width: 768px){
        height: 800px;
    }*/
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => imgStart ? `'col2 col1'` : `'col1 col2'`}; 

    @media screen and (max-width: 768px){
        grid-template-areas: ${({imgStart}) => imgStart ? `'col2 col2' 'col1 col1'` : `'col1 col1' 'col2 col2'`};
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`;

export const Column2 = styled.div`
    margin-bottom: 25px;
    padding: 0 15px;
    grid-area: col2;
    margin-top: -35px;
`;

export const IntroWrapper = styled.div`
    /*min-height: 250px;
    min-width: 400px;
    @media screen and (max-width: 768px){
        max-width: 250px;
    }*/
`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: #ff6633;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({lightText}) => (lightText ? '#f7f8fa' : '#6E5E4E')}; 

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? '#6E5E4E' : '#fff')};
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    max-width: 300px;
    height: 100%;
    /*max-height: 860px;*/
    max-height: 100vh;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Img = styled.img`
    width: 100%;
    /*margin: 0 0 10px 0;*/
    /*padding-right: 0;*/
    display: flex;
    justify-content: center;
    align-items: center;
    filter: opacity(80%);
    
`;

export const ImageLevensPad = styled.img`
    width: 100%;
`;


