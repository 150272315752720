import styled from 'styled-components';

export const SwitchNav = styled.nav`
    background: #000;
    /*background: ${({scrollNav}) => (scrollNav ? '#000' : 'transparent' )}*/
    height: 50px;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

export const SwitchContainer = styled.div`
    /*display: flex;
    justify-content: space-between;*/
    display: block;
    margin-left: auto;
    height: 50px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`
;