import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterNoLink } from './FooterElements';
import {SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink, FooterLinkSameSite, ImgLogo, SocialLink} from './FooterElements';
import {animateScroll as scroll} from 'react-scroll';
import logo from '../../images/logo_white_copy.png';

const Footer = ({isToggled}) => {

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            {isToggled?
                            <FooterLinkTitle>Contact me</FooterLinkTitle>
                            :
                            <FooterLinkTitle>Contacteer mij</FooterLinkTitle>
                            }
                                <FooterNoLink>Quinten Vermoesen</FooterNoLink>
                                <FooterNoLink>+32487 47 33 60</FooterNoLink>
                                <FooterNoLink>vermoesenit@gmail.com</FooterNoLink>
                            </FooterLinkItems>

                            <FooterLinkItems>
                                <FooterLinkTitle>VermoesenIT</FooterLinkTitle>
                                <FooterLinkSameSite to="about">BE0783.652.211</FooterLinkSameSite>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            <ImgLogo src={logo}/>
                        </SocialLogo>
                        <WebsiteRights>
                            Vermoesen © {new Date().getFullYear()}.  
                            {isToggled? 'All rights reserved.' :'Alle rechten voorbehouden'} 
                        </WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="https://www.facebook.com/Vermoesen-IT-113309751349703/" targets="_blank" aria-label="Facebook">
                                <FaFacebook/>
                            </SocialIconLink>

                            <SocialIconLink href="https://www.instagram.com/vermoesenit/" targets="_blank" aria-label="Instagram">
                                <FaInstagram/>
                            </SocialIconLink>
                            
                            <SocialIconLink href="https://www.linkedin.com/in/quinten-vermoesen-76a5181a2/" targets="_blank" aria-label="Linkedin">
                                <FaLinkedin/>
                            </SocialIconLink>
                            
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer

/*
<FooterLinkItems>
                                <FooterLinkTitle>Our socials</FooterLinkTitle>
                                <SocialLink href="https://www.facebook.com/Vermoesen-IT-113309751349703/">Facebook</SocialLink>
                                <SocialLink href="https://www.instagram.com/vermoesenit/" >Instagram</SocialLink>
                                <SocialLink href="https://www.linkedin.com/in/quinten-vermoesen-76a5181a2/">LinkedIn</SocialLink>
                            </FooterLinkItems>

<SocialIconLink href="/" targets="_blank" aria-label="Twitter">
                                <FaTwitter/>
                            </SocialIconLink>


                            */
