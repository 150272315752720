import React from 'react';
import ImageSlider from '../ImageSlider/ImageSlider';
/* import { Button } from 'react-scroll'; */
import { SocialLink, SocialIconLink, InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, ImgWrap, Img, Wrapper} from './InfoElements';
import { SliderData } from '../ImageSlider/SliderData';
import { AiOutlineMail } from "react-icons/ai";
import {  FaInstagram, FaFacebook } from 'react-icons/fa';

const InfoSection = ({isToggled, slider,lightBg, id, imgStart, topLineEN, topLineNL, lightText, headlineEN, headlineNL, darkText, descriptionEN, descriptionNL, buttonLabelEN, buttonLabelNL, img, alt, insta, mail, fb, dark, dark2, primary, BtnLink }) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                
                                <Heading lightText={lightText}>{isToggled? headlineEN:headlineNL}</Heading>
                                <Subtitle darkText={darkText}>{isToggled? descriptionEN: descriptionNL}</Subtitle>
                                {fb?
                                <Wrapper>
                                <SocialIconLink href="https://www.facebook.com/Vermoesen-IT-113309751349703/" targets="_blank" aria-label="Facebook">
                                                <FaFacebook/>
                                </SocialIconLink>
                                <SocialLink href="https://www.facebook.com/Vermoesen-IT-113309751349703/" targets="_blank" aria-label="Facebook">
                                Vermoesen-IT
                                </SocialLink>
                                </Wrapper> : ""}

                                {insta?
                                <Wrapper>
                                <SocialIconLink href="https://www.instagram.com/vermoesenit" targets="_blank" aria-label="Instagram">
                                                <FaInstagram/>
                                </SocialIconLink>
                                <SocialLink href="https://www.instagram.com/vermoesenit" targets="_blank" aria-label="Instagram">
                                @vermoesenit
                                </SocialLink>
                                </Wrapper> : ""}

                                {mail?
                                <Wrapper>
                                <SocialIconLink href="https://www.instagram.com/vermoesenit" targets="_blank">
                                <AiOutlineMail/> 
                                </SocialIconLink>
                                <SocialLink href="mailto:vermoesenit@gmail.com" targets="_blank">vermoesenit@gmail.com
                                </SocialLink>
                                </Wrapper>
                                : ""}
                            </TextWrapper>
                        </Column1>
                        <Column2>
                        {slider? 
                        <ImgWrap>
                            <ImageSlider slides={SliderData} />
                        </ImgWrap> :
                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>}
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>  
        </>
    );
};

export default InfoSection;

/* op lijn 17:
<TopLine>{isToggled? topLineEN:topLineNL}</TopLine>

na column2

<BtnWrap>
                                    <Button 
                                        to={BtnLink}
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}>
                                        {buttonLabel}</Button>
                                </BtnWrap>

<ImgWrap>
<Img src={img} alt={alt}/>
</ImgWrap>
*/


/*                                         smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}>*/
