import React, {useState, useEffect, videoRef, setVideoPaused} from 'react';
import vid from '../../videos/man_typen_final_vid2.mp4';
import { VideoBg, Img, HeroContainer, HeroBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, HeroPLittle} from './HeroElements';
import { Button } from '../ButtonElements';
import { isSafari, isMobile, isIOS } from 'react-device-detect';
import './HeroElements.css';

/* 'https://www.dropbox.com/s/2vno5f2qhrpk8yl/video_Trim_compress.45cd5a7d.mp4?raw=1' */

const HeroSection = ({isToggled}) => {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    };
/* 'https://www.dropbox.com/s/2vno5f2qhrpk8yl/video_Trim_compress.45cd5a7d.mp4?raw=1' */

/* 'https://www.dropbox.com/s/2vno5f2qhrpk8yl/video_Trim_compress.45cd5a7d.mp4?raw=1'*/
    return (
        <div>
        {isToggled?
        <HeroContainer>
            <HeroBg>
            {isMobile && isIOS?
            <Img src={require('../../videos/man_typen_final_img.jpg').default} alt=""/>
            :
            <VideoBg autoPlay loop muted playsInline src={vid} type='video/mp4'/> 
            }
            </HeroBg>
            <HeroContent>
                <HeroH1>Not your ordinary website</HeroH1>
                <HeroP>
                Get a website which turns heads.
                </HeroP> 
                <HeroBtnWrapper>
                    <Button 
                        to='contact' 
                        onMouseEnter={onHover} 
                        onMouseLeave={onHover}
                        primary='true'
                        dark='true'
                    >
                        Get in touch {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>

                :

                <HeroContainer>
                    <HeroBg>
            {isMobile && isIOS?
            <Img src={require('../../videos/man_typen_final_img.jpg').default} alt=""/>
            :
            <VideoBg autoPlay loop muted playsInline src={vid} type='video/mp4'/> 
            }
            </HeroBg>
                <HeroContent>
                    <HeroH1>Niet zomaar een website</HeroH1>
                    <HeroP>
                    Bemachtig een website waar mensen naar omkijken.
                    </HeroP> 
               
                    <HeroBtnWrapper>
                        <Button 
                            to='contact' 
                            onMouseEnter={onHover} 
                            onMouseLeave={onHover}
                            primary='true'
                            dark='true'
                        >
                            Bereik mij {hover ? <ArrowForward /> : <ArrowRight />}
                        </Button>
                    </HeroBtnWrapper>
                </HeroContent>
            </HeroContainer>

        }
        </div>
    );
};

export default HeroSection;

/*
            
<VideoBg autoPlay loop muted src='https://www.dropbox.com/s/2vno5f2qhrpk8yl/video_Trim_compress.45cd5a7d.mp4?raw=1' type='video/mp4'/>


<div dangerouslySetInnerHTML={{ __html: `
                <video
                autoplay
                loop
                muted
                class="VideoBg"
                src="https://www.dropbox.com/s/2vno5f2qhrpk8yl/video_Trim_compress.45cd5a7d.mp4?raw=1"
                type="video/mp4"
                />
            ` }}/>



              <div dangerouslySetInnerHTML={{ __html: `
            <video
            id="vid"
            class="VideoBg"
            loop
            autoplay
            muted
            playsinline>
            <source src="https://www.dropbox.com/s/2vno5f2qhrpk8yl/video_Trim_compress.45cd5a7d.mp4?raw=1" type="video/mp4"/>
        </video>
        <script>
Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get: function () {
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
});

$('body').on('click touchstart', function () {
            const videoElement = document.getElementById('vid');
            if (videoElement.playing) {
                // video is already playing so do nothing
            }
            else {
                // video is not playing
                // so play video now
                videoElement.play();
            }
    });

</script>
        `}}/>
            */