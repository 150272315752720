export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Who am I?',
    headline: 'Electrical Engineer with a passion for web and automation',
    description: '"As an Electrical Engineer, I learned to think analytically and apply my problem solution capabilities to the things I am passionate and creative about: custom websites and webapps with all its features." ',
    buttonLabel: 'Read more',
    imgStart: false,
    slider: false,
    /*img: require('../../images/svg-2.svg').default,*/
    /*img:'https://uc499f1194c38cd9d11b9f0ec7f0.previews.dropboxusercontent.com/p/thumb/ABhNtKyOO5UAoZz5rm3FgGYmVf4E52-XGV5cToMf2R_pjsHgowkxdy-32Gyk06DLMvEBOfOxYk4psqz-lxTcu4z7SwJTwWdcLcoHZmFO8uIkFPE_pc3clDt2MTlxdEwE-qOhW3PROfGyRx2T66dJlHFDKFsfH65mmFXzdcg7FCwCMIlhSXoZNZgGkll3lWOdH7c1EUcy_TKwo4jnviEPKagvpvePXETL-JZNXu2xCc57z35Zu3hROCvHm5Siv1NYiU-qjRHDiraJH_J2E7EQCGXSRaB2NKuzPZSnvuz3E8hIgn5hlfwBemju0XiG6TNfA-gAQb41GoArtfRa6odjVk0wCPFnyLBepwYhCpX9-FsMS59pNN5rvccF6BJnpRs1O2euOqUmFCQlq3oQ0ltkY55gTWi4XGy81LyVizMC4GSL8Q/p.png',*/
    alt: 'civil engineer',
    dark: false,
    primary: false,
    darkText: true
};

/*
export const homeObjThree = {
    id: 'portfolio',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'What have I made?',
    headline: 'You are not our first customer, but certainly our most important one!',
    description: 'Have a look at the previous projects I made!',
    buttonLabel: 'View projects',
    imgStart: false,
    slider: true,
    img: require('../../images/svg-3.svg').default,
    alt: 'pair programming',
    dark: false,
    primary: false,
    darkText: true
};*/

export const homeObjFour = {
    id: 'contact',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLineEN: 'How to contact me?',
    headlineEN: 'Get in touch!',
    descriptionEN: 'Do not hesitate to ask a question, send some suggestions or just leave a message you like! ',
    buttonLabelEN: 'Leave a message',
    topLineNL: 'Hoe kan je me contacteren?',
    headlineNL: 'Contacteer me!',
    descriptionNL: 'Aarzel niet om een vraag te stellen, suggesties te sturen of laat gewoon een berichtje achter! ',
    buttonLabelNL: 'Laat een berichtje achter',
    insta: true,
    mail: true,
    fb: true,
    imgStart: false,
    slider: false,
    /*img: require('../../images/svg-4.svg').default,*/
    /*img: 'https://ucae40ca270907a50377926b18f2.previews.dropboxusercontent.com/p/thumb/ABhmk8TOVirZ8CKZcKnEcz3BQnOfrPBtGJlqplVg6y0r27PMXl5sSfSFxNusGzc5nrc0EHfDgbySYzu35sqN97euLvcj8kLvCyDPtNnI9Mv6jnkr-MFu4p4o_oH9h9JXmeOIgX9qhfJO2PUggTsEcqpUy6LeaPEjkNwa5BybANnZtmMDLRGw21mbr2BILrSbyalRqNEdMQrTwCr115vZ3D8CdsrHVubRAOc-TtB0DxhO9R3W0-h08V3aBOeDEpGxDwkWvGE9hYM6jeF9Yn0Sctdz3nWyYPckTP83Af13wI8UtayMBvrbn0-i8O2WUmBISCH1OOQOR2_kcoMdRTpq3UBV4NQJhzIkBeEst-Sb9_u-Gu2rN4H9TFagRPKYQAMUrYxHNUetgNlz2b_d985snVNWbNCKNe0sLzg4MTpmbqxyug/p.png',*/
    img: 'https://www.dropbox.com/s/ox5g7cfdg0rjxir/svg-4.f19bc065.svg?raw=1',
    alt: 'contact me',
    dark: true,
    primary: true,
    darkText: false
};





