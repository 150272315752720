import React, {useState, useEffect} from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjFour} from '../components/InfoSection/Data';
import Services from '../components/Services';
import Footer from '../components/Footer';
import Portfolio from '../components/Portfolio/portfolio';
import { homeObjThree } from '../components/Portfolio/portfoliodata';
import About from '../components/About/about';
import { homeObjOne } from '../components/About/aboutData';
import Switch from '../components/Switch';
import Header from '../components/HeaderNew/Header';

const Home = () => {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const [langToggled, setlangToggled] = useState(false);
    const onLangToggle = () => setlangToggled(!langToggled);

    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
          width: undefined,
          height: undefined,
        });
      
        useEffect(() => {
          // only execute all the code below in client side
          if (typeof window !== 'undefined') {
            // Handler to call on window resize
            function handleResize() {
              // Set window width/height to state
              setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
              });
            }
          
            // Add event listener
            window.addEventListener("resize", handleResize);
           
            // Call handler right away so state gets updated with initial window size
            handleResize();
          
            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
          }
        }, []); // Empty array ensures that effect is only run on mount
        return windowSize;
      }
    
      const size = useWindowSize();

    return (
        <>
        {size.width<768?
        <>
        <Sidebar isOpen={isOpen} toggle={toggle} langToggle={onLangToggle} isToggled={langToggled}/>
        <Navbar toggle={toggle} langToggle={onLangToggle} isToggled={langToggled}/>
        </>
        :
        <>
        <Navbar toggle={toggle} langToggle={onLangToggle} isToggled={langToggled}/>
        <Sidebar isOpen={isOpen} toggle={toggle} langToggle={onLangToggle} isToggled={langToggled}/>
        </>
        }
        <HeroSection isToggled={langToggled}/>
        <About {...homeObjOne} isToggled={langToggled}/>
        <Services isToggled={langToggled}/>
        <Portfolio {...homeObjThree} isToggled={langToggled}/>
        <InfoSection {...homeObjFour} isToggled={langToggled}/>
        <Footer isToggled={langToggled}/>
        </>
    );
};

export default Home;

/*
  <Sidebar isOpen={isOpen} toggle={toggle} isToggled={langToggled}/>
        <Navbar toggle={toggle} langToggle={onToggle} isToggled={langToggled}/>

             <HeroSection isToggled={langToggled}/>
        <About {...homeObjOne} isToggled={langToggled}/>
        <Services/>
        <Portfolio {...homeObjThree}/>
        <InfoSection {...homeObjFour}/>
        <Footer/>
     

        <Intro/>
        <InfoSection {...homeObjThree}/>
        */
