import React, {useState, useEffect} from 'react';
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks,  NavBtn, NavBtnLink, ImgLogo, NavSwitch, NavColumn1, NavColumn2, NavColumn3} from './NavbarElements';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import logo from '../../images/logo_white_copy.png';
import ReactSwitch from 'react-switch';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

const Navbar  = ({toggle, isToggled, langToggle}) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };


    return (
        <IconContext.Provider value={{color:'#fff'}}>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to='/' onClick={toggleHome}>
                        <ImgLogo src={logo}/>
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars style={{color:'#ffaf7f'}}/>
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks 
                            to='about'
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                            >{isToggled? 'Get to know me' : 'Leer me kennen'}</NavLinks> 
                        </NavItem>

                        <NavItem>
                            <NavLinks 
                            to='services'
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                            >{isToggled? 'What I offer' : 'Wat ik aanbied'}</NavLinks> 
                        </NavItem>

                        <NavItem>
                            <NavLinks 
                            to='portfolio'
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                            >{isToggled? 'Portfolio' : 'Portfolio'} </NavLinks> 
                        </NavItem>

                        <NavItem>
                            <NavLinks
                            to='contact'
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                            >{isToggled? 'Contact' : 'Contact'}</NavLinks> 
                        </NavItem>
                    </NavMenu>  

                    <NavColumn3>
                          <ToggleSwitch langToggle={langToggle}/>
                    </NavColumn3>
                    
                </NavbarContainer>
            </Nav>
            </IconContext.Provider>

    );
};

export default Navbar; 
 
/*
                        <NavColumn2>
                          <ToggleSwitch langToggle={langToggle}/>
                          </NavColumn2>

        <NavSwitch>
                          
                          <NavColumn2>
                          <ToggleSwitch langToggle={langToggle}/>
                          </NavColumn2>
                          </NavSwitch>


  <NavColumn1>
                        <NavBtnLink to='contact'
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}>Get your website!</NavBtnLink>
                        </NavColumn1>
                        */