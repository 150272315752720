import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll';
import {FaTimes} from 'react-icons/fa'

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    /*background: #0d0d0d;*/
    background: #f9f9f9;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen  ? '100%' : '0' )};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    /* top: 0; */
`;

export const CloseIcon = styled(FaTimes)`
    color: #A28A72;
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`;

export const SidebarWrapper = styled.div`
    color: #fff;
`;

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 80px);
    }
`;

export const SidebarLink = styled(LinkS)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #A28A72;
    cursor: pointer;

    &:hover {
        color: #ffaf7f;
        transition: 0.2s ease-in-out;
    }
`;

export const SidebarBtnWrap = styled.div`
    display: flex;
    justify-content: center;
`;

export const SidebarRoute = styled(LinkS)`
    border-radius: 50px;
    /*background: #ff6633;*/
    background: #ffaf7f;
    white-space: nowrap;
    padding: 16px 54px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ccc;
        color: #A28A72;
        /*color: #ffaf7f;*/
    }
`;

export const SidebarTranslator = styled.div`
    align-items: center;
    padding: 10px;
`;


