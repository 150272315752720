import React from 'react';
import ImageSlider from '../ImageSlider/ImageSlider';
/* import { Button } from 'react-scroll'; */
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, ImgWrap} from './portfolioElements';
import { SliderData } from '../ImageSlider/SliderData';

const Portfolio = ({isToggled, slider,lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, img, alt, dark, dark2, primary }) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                <TopLine>{isToggled?'What have I made?': 'Wat heb ik al gemaakt?'}</TopLine>
                 <Heading lightText={lightText}>{isToggled?'You are not our first customer, but certainly our most important one!' : 'Je bent niet onze eerste klant maar zeker en vast onze meest belangrijke!'}</Heading>
                        <ImgWrap>
                            <ImageSlider isToggled={isToggled} slides={SliderData} />
                        </ImgWrap>
                </InfoWrapper>
            </InfoContainer>  
        </>
    );
};

export default Portfolio;

/* na column2

<ImgWrap>
<Img src={img} alt={alt}/>
</ImgWrap>
*/


/*                                         smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}>
                                        
                                        
                                        button:
                                          <BtnWrap>
                                    <Button 
                                        to='home'
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}>
                                        {buttonLabel}</Button>
                                </BtnWrap>
                                
                                
                                
                                
code 15/06/2022:
import React from 'react';
import ImageSlider from '../ImageSlider/ImageSlider';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, ImgWrap} from './portfolioElements';
import { SliderData } from '../ImageSlider/SliderData';

const Portfolio = ({slider,lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, img, alt, dark, dark2, primary }) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                <TopLine>{topLine}</TopLine>
                 <Heading lightText={lightText}>{headline}</Heading>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <Subtitle darkText={darkText}>{description}</Subtitle>
                              
                            </TextWrapper>
                        </Column1>
                        <Column2>
                        <ImgWrap>
                            <ImageSlider slides={SliderData} />
                        </ImgWrap> 
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>  
        </>
    );
};

export default Portfolio;                                */
