export const SliderData = [
    {
      image:
        /*require('../../images/site_pablo.png').default,*/
        /*'https://uc499f1194c38cd9d11b9f0ec7f0.previews.dropboxusercontent.com/p/thumb/ABhNtKyOO5UAoZz5rm3FgGYmVf4E52-XGV5cToMf2R_pjsHgowkxdy-32Gyk06DLMvEBOfOxYk4psqz-lxTcu4z7SwJTwWdcLcoHZmFO8uIkFPE_pc3clDt2MTlxdEwE-qOhW3PROfGyRx2T66dJlHFDKFsfH65mmFXzdcg7FCwCMIlhSXoZNZgGkll3lWOdH7c1EUcy_TKwo4jnviEPKagvpvePXETL-JZNXu2xCc57z35Zu3hROCvHm5Siv1NYiU-qjRHDiraJH_J2E7EQCGXSRaB2NKuzPZSnvuz3E8hIgn5hlfwBemju0XiG6TNfA-gAQb41GoArtfRa6odjVk0wCPFnyLBepwYhCpX9-FsMS59pNN5rvccF6BJnpRs1O2euOqUmFCQlq3oQ0ltkY55gTWi4XGy81LyVizMC4GSL8Q/p.png',*/
        'https://www.dropbox.com/s/xzejctnql3dxswq/site_pablo_compress.png?raw=1',
      descriptionEN: 
        "A personal web-app for a famous barber shop in Oostende. A web-app is exactly as the name suggest: it gives the impression of an app with the ease of looking it up on the internet like a website! The website itself shows simplicity with a short introduction, some examples of hair cuts and links to a tool to make appointments and social media. See for yourself at ",
      descriptionNL:
         "Een web-app voor een bekende barber in Oostende. Een web-app is exact zoals de naam doet vermoeden: het geeft de indruk van een app te zijn, met het gemak dat je hem kan opzoeken zoals een website. Deze eenvoudige web-app toont een korte introductie, foto's van hair cuts en links naar sociale media. Neem eens een kijkje op ",
        altname:
        'screenshot of website of pablo the barber',
      pablo: true,
      ann: false
    },
    {
      image:
        /*require('../../images/pablo_phone2.png').default,*/
        /*'https://uccdcc8ce1e4cb92d04a5bdddcb6.previews.dropboxusercontent.com/p/thumb/ABglr-eSwOlqKiKtaoXRnzlePfiAa4ogDvtlxHYpVgsYT9SfpFF165j8Ukevt6YJ1PGy0SgEHWPBQI9AuXUdf4csu6XaP3WspAwAJslczANiU8WwDEtclcZKPeN477931TPPlLMeL6T160xHCMHpqZ3hZr7na-1WOG6k4WIfMtctvARltaZRjWtygQY6ubJPGG87cc4pCNUG1vXO3kVf3gyOA_C1kt9zmoIVrF8NhWdhPp1tE_Bx2oyTUGu3t-10eTCyI4ELEDSsVEX_DYvX1n8WV-8K2ydpJxjgFnfeembD_HeHXDHR3qYx9wcvsovbv2SBSNZtu7iw9DjtFSxD6go8cW0kzs3Y2Hl_nNl6fejJY6ajlWRwjRUOzvm8BACxjmQGNOJJlrhGH3VFB2V4Q5_8J4vdc13KFIVIJvWmSkY_AA/p.png',*/
        'https://www.dropbox.com/s/9lwa2td9l1qao9u/pablo_phone2_compress.png?raw=1',
      descriptionEN: 
        'Being a web-app, it is also easier to show on other devices apart from a computer, like your phone or iPad! Have a look at this web-app showing on a phone, which of course has the same functionality as on a computer. See for yourself at ',
      descriptionNL:
      "Het feit dat het een web-app is, maakt het ook makkelijker om te tonen op andere toestellen dan een computer zoals iPads en smartphones. Kijk eens hoe deze web-app eruit ziet op een smartphone door te surfen naar ",
        altname:
        'screenshot of web-app of pablo the barber on phone',
        pablo: true,
        ann: false
    },
    {
      image:
        /*require('../../images/pablo_phone1.png').default,*/
        /*'https://uc9e7a668f690f19f60de2c9f06f.previews.dropboxusercontent.com/p/thumb/ABhPP_6LdPFS020IHgrT75tYRrOdMX5GkI3D7Fw-EdwPeoS2OGaVnEFPdPtyz_6CEnbhUs0-HWYGydgf16xWXrTYfqKXi4TsDpojPyFyiIxQ-QIf5DBC6pcjelALRXFvD-v-Vt5fGGyHqL1sXJiurEqDCEiYEqM5f78d4dZTbpnlVGSLCqh1u5O2ZpMLubvs6Owcsca_WuPvEjT-ga6SDdko0eHrCajloOkzi1yF8q1nr9qlf_rEp72ZuvSSx7LOFGU15R4N_x7Nxrun1fkktGndpQt-GuA_ocguMfSedcKglm6YadU7bI675Y2Bg9-OIr38GwXLkeDMWS6ZToRmfUHwOwqonmSo9ZbnzNA134YKZcpR2EiP6IOrBat1ISReSyrWlDAFBHFTPE0zBb9zakaWwYBFmvcuz6EQ3OaTDk2sNQ/p.png',*/
        'https://www.dropbox.com/s/qfmj5tb9btwq8e2/pablo_phone1_compress.png?raw=1',
      descriptionEN: 
        'Being a web-app, it is also easier to show on other devices apart from a computer, like your phone or iPad! Have a look at this web-app showing on a phone, which of course has the same functionality as on a computer. See for yourself at ',
      descriptionNL:
      "Het feit dat het een web-app is, maakt het ook makkelijker om te tonen op andere toestellen dan een computer zoals iPads en smartphones. Kijk eens hoe deze web-app eruit ziet op een smartphone door te surfen naar www.pablothebarber.be op je smartphone.", 
        altname:
        'screenshot of web-app of pablo the barber on phone',
        pablo: true,
        ann: false
    },
    {
      image:
        /*require('../../images/bakery_stop_frontpage.PNG').default,*/
        /*'https://uc5c2bbe63034ff22564e4ae4ae3.previews.dropboxusercontent.com/p/thumb/ABjvOm-e1gcJt35oQAbaYD5Xslyrt_iVaQC8QYKJFn6cj1Nln9S5qW2YhuzSQtxE2u54Rc30eA_8HIQCEGXullITjO8c5BN9BgAxYUqAnyH-tV6s2meXJXDq9KeDM58tY_KGWqX8Fl6QLmdy5wv2P4y8ZB3a_UsPNhG48rE4V1rmJDapZHuw28Ovw3c_5bRju9yblLreMp9NPiC49gCoLyRENOI9EkIttyYSEALeemtH0UgOGOk6aE5irvUQv6kmgOTNMNJwamMFyCcoJheMeMJ7ITK5alC8MGbSBTxUrySY2GPdDMzYHh2S2G-3UBZH7ow-ueUJ74So3EiPqsLOWqNsduBxpDs-APTAsXVfiBW2EKxa4AeT4_ELLQSB96KCB-w9EjWUfaj7gbvh1CM_ETUit9FLtSUxZptYdDO8TZ-xfA/p.png',*/
        'https://www.dropbox.com/s/x113v93h2at44fc/bakery_stop_frontpage_compress.png?raw=1',
      descriptionEN:
        'This is a a web-app for an artsinal bakery shop. A web-ap with some nifty features was only fitting for a bakery with such delicious foods. Scroll to the right to explore what this web-app was capable of!',
      
      descriptionNL:
      "Dit is een web-app voor een artisanaal bakkerijtje. Een web-app met een paar vernuftige snufjes paste bij zo een lekker gebak! Ga naar rechts om te ontdekken wat deze web-app allemaal te bieden heeft.",
        altname:
        'screenshot of website from a bakery shop',
        pablo: false,
        ann: false
    },
    {
      image:
        /*require('../../images/bakery_stop_order.PNG').default,*/
        /*'https://uc4468e4f4b3b0a6635fd946ce27.previews.dropboxusercontent.com/p/thumb/ABh7rALWSLPP0ZgcZB9CatEmz1uZEcX9Y8B3gIXt6XBWZr4feQXBrjFLB_VWzq85Hs6xCrQAUJjxnSfwKpPi-M0_c4xxOsuaMSYv9UrmUIkfZPYYH-C5zD6uxjS-rq8uamRyb6gJ6d67wnL-0ad0jiY8IMUsdonCqhobXuu-Zorjx0BYZsGaiKtkmuKWbioIYwbXGSKm9mm-D9MJYLBrR37vbr3E9R6BgCN4__Xs3zgAfFeAxNJWFt0BL-IaLr7UCTd6GGvTMNnJSfdCkbmgdUu6v88k5BHT6D8P9hvfvVRCgDp39_RNnLw__3wGkSU0dicA7MYSntDz26N3Yk95S3vW3DfSHktLM8HEDi9PGL5Au9KmNVhrFhhACw8oc2pSHBRUP3Uvr0FlR42K49oToTbdGHewBMIgt5RcSVhgXqiHVg/p.png',*/
        'https://www.dropbox.com/s/clnqhqfw2mywwzk/bakery_stop_order_compress.png?raw=1',
      descriptionEN: 
        'A bakery shop of course needs a web shop. One was build, including season-dependent offers. The buyer selects the sweets, fills out some basic information and done! There is a possibility of delivery.',
      
      descriptionNL:
      "Een bakkerij heeft natuurlijk een webshop nodig. Eentje was gemaakt met seizoensgebonden aanbiedingen. De koper selecteert de zoetigheden, vult wat basisinfo in nodig voor de bestelling en klaar! Er is ook een mogelijkheid tot levering.",
        altname: 
        'screenshot of webshop from a bakery shop',
        pablo: false,
        ann: false
    }
    ,
    {
      image:
        /*require('../../images/bakery_stop_order_form.PNG').default,*/
        /*'https://ucee14c401d27eaa536dc43e3776.previews.dropboxusercontent.com/p/thumb/ABj2drXUgQIb1MZvRztl5ecA60v3N3DRQtV6i2CmqOmPubTvgeqCgbcwQDJXWbi1A8Gi2mDJumSmqsajK8tVjfYdLsGFjwoQSB8pUnHdXNp6LypIGsSp4eQtTzNX-JgqYkR-gcaJCS4BEiWJDrSnhMnQEa4JvIO7eF5Sxox5GjoBya3sI3Y4lPm4KKwZg0IVQQ5wWfTuQnB1mQBEQbeXGXXqAOct6lDs35MA3IovRXX3DCt3ne53MbxSAlkjRFOLVQGntpe8X1rGqYWRztRTZGkGesmxU7XcOgMegUnucyAALQJd0I14TSw_GGk_Edqhrjcr2a5kALgBXLl_affp4YP7KiWsZNOvZVXLmPXwBRO8_PeKbDd3MFDgSQBiEmqyVGYSw92wyXFcVy0kEoCBubEi1LYIz-3XKFDiflqUBCEmgA/p.png',*/
        'https://www.dropbox.com/s/l4vbazm7d9ioe8h/bakery_stop_order_form.47a9457e.PNG?raw=1',
        descriptionEN: 
        'A bakery shop of course needs a web shop. One was build, including season-dependent offers. The buyer selects the sweets, fills out some basic information and done! There is a possibility of delivery.',
        descriptionNL:
        "Een bakkerij heeft natuurlijk een webshop nodig. Eentje was gemaakt met seizoensgebonden aanbiedingen. De koper selecteert de zoetigheden, vult wat basisinfo in nodig voor de bestelling en klaar! Er is ook een mogelijkheid tot levering.",
        altname: 
        'screenshot of webshop from a bakery shop',
        pablo: false,
        ann: false
    }
    ,
    {
      image:
        /*require('../../images/bakery_stop_checks.PNG').default,*/
        /*'https://ucc75d65745dfff03e2868a9f4f5.previews.dropboxusercontent.com/p/thumb/ABhzGvo1W2r-uHsYR-4oenwtxEJ8EI4sgJcyjwZgljUx9LCmpFLJ15SSHCdA7vAxtXoLzyiYfh3OFXe9ghYm_wx5-ms1ws8zITdrRPWFQoxhqgOMtJyyqEkQ-iKy8r9O3lSPEvQt_CeG6W9zeMperfNjXshcMYVcmNYmajs4AZjmnUIXSWug6Y_sVmXIBn0-Xn7xZmbJO1UJt3OHTsK7mMujg5TkdYagFnK4OTexUIVeCePIwOjZwOrhqlgovhMkCaolRr22ks5gOw5reKP1CKCciI3z40DWSUBx1pj8-22BQErfgwE9o6ydpiyGd2cN-bIXs6jV5ZQZIost1EjshrJEXy4nkFqHN2BdJLdSk8APi27R4Lm2Zio4i_591AVuqgn6MIRE4skr2Je_Oy3DcStn1ykHipocMFNvOdNLAEhR2w/p.png',*/
        'https://www.dropbox.com/s/h5lkxx7ns8pifnf/bakery_stop_checks.6a191675.PNG?raw=1',
      descriptionEN: 
        'To keep track of all the orders, the owners of the bakery shop can log in with a special account and see all the orders. The owners can even select on the date the order was placed or on the date the customer wants its treats! Also other info that the client gave is visible, handy having the address available in one click for deliveries!',
      descriptionNL:
      "Om alle orders bij te houden, kunnen de eigenaars van de bakkerij inloggen met een speciaal account om alle bestellingen te zien. De eigenaars kunnen selecteren op datum wanneer de bestelling geplaatst werd of tegen wanneer deze moet gemaakt worden. Hier zie je ook alle adressen per bestelling in 1 klik, handig om per dag te kunnen zien waar je moet leveren!",
        altname: 
        'screenshot of online order system of a bakery stop',
        pablo: false,
        ann: false
    },
    {
      image:
        /*require('../../images/bakery_stop_checks.PNG').default,*/
        /*'https://ucc75d65745dfff03e2868a9f4f5.previews.dropboxusercontent.com/p/thumb/ABhzGvo1W2r-uHsYR-4oenwtxEJ8EI4sgJcyjwZgljUx9LCmpFLJ15SSHCdA7vAxtXoLzyiYfh3OFXe9ghYm_wx5-ms1ws8zITdrRPWFQoxhqgOMtJyyqEkQ-iKy8r9O3lSPEvQt_CeG6W9zeMperfNjXshcMYVcmNYmajs4AZjmnUIXSWug6Y_sVmXIBn0-Xn7xZmbJO1UJt3OHTsK7mMujg5TkdYagFnK4OTexUIVeCePIwOjZwOrhqlgovhMkCaolRr22ks5gOw5reKP1CKCciI3z40DWSUBx1pj8-22BQErfgwE9o6ydpiyGd2cN-bIXs6jV5ZQZIost1EjshrJEXy4nkFqHN2BdJLdSk8APi27R4Lm2Zio4i_591AVuqgn6MIRE4skr2Je_Oy3DcStn1ykHipocMFNvOdNLAEhR2w/p.png',*/
        'https://www.dropbox.com/s/dhad5nsl664fvlu/ann-art.PNG?raw=1',
      descriptionEN: 
        'A project for a visual artist who crafts statues and hosted a exhibition. A web-app was built which showcased her statues together with some explanation. At the exhibition, a QR code on each statue was visible which directed the visitors to the appropriate webpage of the statue. You can visit the web-app at ',
      descriptionNL:
      "Een project voor een beeldende kunstenares die een tentoonstelling houdt. Een web-app werd gebouwd die haar beelden toonden samen met wat uitleg. In de tentoonstelling, een QR code was zichtbaar die de bezoekers verwees naar de juiste webpagina voor dat beeld. Neem zelf eens een kijkje op ",
        altname: 
        'screenshot of online order system of a bakery stop',
        pablo: false,
        ann: true
    },
    {
      image:
        /*require('../../images/bakery_stop_checks.PNG').default,*/
        /*'https://ucc75d65745dfff03e2868a9f4f5.previews.dropboxusercontent.com/p/thumb/ABhzGvo1W2r-uHsYR-4oenwtxEJ8EI4sgJcyjwZgljUx9LCmpFLJ15SSHCdA7vAxtXoLzyiYfh3OFXe9ghYm_wx5-ms1ws8zITdrRPWFQoxhqgOMtJyyqEkQ-iKy8r9O3lSPEvQt_CeG6W9zeMperfNjXshcMYVcmNYmajs4AZjmnUIXSWug6Y_sVmXIBn0-Xn7xZmbJO1UJt3OHTsK7mMujg5TkdYagFnK4OTexUIVeCePIwOjZwOrhqlgovhMkCaolRr22ks5gOw5reKP1CKCciI3z40DWSUBx1pj8-22BQErfgwE9o6ydpiyGd2cN-bIXs6jV5ZQZIost1EjshrJEXy4nkFqHN2BdJLdSk8APi27R4Lm2Zio4i_591AVuqgn6MIRE4skr2Je_Oy3DcStn1ykHipocMFNvOdNLAEhR2w/p.png',*/
        'https://www.dropbox.com/s/ywjl1no09b5djuf/ann-art2.png?raw=1',
      descriptionEN: 
        'This is the phone view of the web-app. You can visit the web-app at ',
      descriptionNL:
      "Dit is het smartphone beeld van de web-app. Neem zelf eens een kijkje op ",
        altname: 
        'screenshot of online order system of a bakery stop',
        pablo: false,
        ann: true
    },

    
/*
    ,
    {
      image:
        require('../../images/site_laure_contact.PNG').default,
        description: 
        'Curious visitors of the web-app with questions can leave them in the contact form.'
    }
    ,
    {
      image:
        require('../../images/site_laure_phone.PNG').default,
        description: 
        'This web-app is also available on the phone and iPad, ofcourse keeping all the functionalities mentioned previously. As an owner, just log in to your web-app and you have all the information about the order available on the click of a button!'
    }*/
  ];