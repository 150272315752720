import React from 'react';
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP} from './ServicesElements';

const Services = ({isToggled}) => {
    return (
        <ServicesContainer id="services">
            {isToggled?
            <ServicesH1>Our services</ServicesH1>
            :
            <ServicesH1>Onze diensten</ServicesH1>
            }
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src='https://www.dropbox.com/s/crtoxe6660eorwy/shopping.08a7ed73.svg?raw=1'/>
                    <ServicesH2>Webshops</ServicesH2>
                    {isToggled?
                    <ServicesP>Webshops with integrated database and payment system.</ServicesP>
                    :
                    <ServicesP>Webshops met geïntegreerde database en betalingssysteem.</ServicesP>
                    }
                </ServicesCard>

                <ServicesCard>
                    <ServicesIcon src='https://www.dropbox.com/s/8xju7gj0em2cn8t/business.3d6fb824.svg?raw=1'/>
                    <ServicesH2>Business websites</ServicesH2>
                    {isToggled?
                    <ServicesP>Business website with or without database which is tailored to your needs.</ServicesP>
                    :
                    <ServicesP>Business website met of zonder database, afgestemd op uw behoeften.</ServicesP>
                    }
                </ServicesCard>

                <ServicesCard>
                    <ServicesIcon src='https://www.dropbox.com/s/u1dmeesl7czihoa/data.4a21c886.svg?raw=1'/>
                    <ServicesH2>Apps</ServicesH2>
                    {isToggled?
                    <ServicesP>That 'golden idea' app or an app for your business - both for Apple and Android.</ServicesP>
                    :
                    <ServicesP>Die ene 'superidee'-app of een app voor uw bedrijf - beide voor Apple en Android.</ServicesP>
                    }
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    );
};

export default Services;

/* 'https://ucce7cc70bfdc59952a48c8eff1c.previews.dropboxusercontent.com/p/thumb/ABh4ADVv1pXWHlfpD6dH-8hqaFL0XJigqNUQbjcXIT7leazWrXX_xU84X2JSvRGO0o7Ymcl75oH__1veKEnm4dao6Lz57BAEddW3uoH7PO0dQM43W14mTynRMu3QyG8wniun-lWjfhtvROYsYIJ8wCBfBjjKpmLgc61OAbajer92hK-bpvqTaz6DgGa8tVGxyO7XZe8fkPOmNRJRp_Vubu09oXoqGRJPPig9cT0O-Lr9YLm47srMvfSj94eRT4W1ZkITuXwhIpnRgjhc2jwbrZBwvNle1BqM2FW7_7owwk9jOu4CWBsLwhr8ECXP-mCZgsTQ9W8AGgcc2RIgF3pI0QRdDsJhxCHpFat5W1fxsGIIvf7gqNfg6pUXZvPKaxOiW3OecwEQ49bsJY46HyWzV_7iJktdYYOhzzQAddAZ-RygTg/p.png' */
