import React from 'react';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import {SwitchNav, SwitchContainer} from './SwitchElements';

function Switch({langToggle}) {
  return (
      <SwitchNav>
        <SwitchContainer>
            <ToggleSwitch langToggle={langToggle}/>
        </SwitchContainer>
    </SwitchNav>
  );
};

export default Switch;




                        