import "./intro.css";
const Intro = ({introText, nameText, itema, itemb, itemc, itemd, iteme}) => {
  return (
    <div className="i">
    <div className="i-left">
      <div className="i-left-wrapper">
        <h2 className="i-intro">{introText}</h2>
        <h1 className="i-name">{nameText}</h1>
        <div className="i-title">
          <div className="i-title-wrapper">
            <div className="i-title-item">{itema}</div>
            <div className="i-title-item">{itemb}</div>
            <div className="i-title-item">{itemc}</div>
            <div className="i-title-item">{itemd}</div>
            <div className="i-title-item">{iteme}</div>
          </div>
        </div>

        
      </div>

    </div>
  </div>
  );
};

export default Intro;

/* Welcome to this website, my name is
Quinten Vermoesen
Loves to create
Electrical Engineer
Data Engineer
Full stack web enthusiastic
Gets energy from being creative
*/

/*
 Creating things. That's what I have always been passionate about. From drawing to knitting and making small, little wooden "machinery" as a little kid, it were all hobbies of mine.
            Growing up, my fascination for cooking and the broad world of computers came to be. This inspired me to study Electrical Egineering, with a Master in Communication and information technology.
            Why? Because I wanted to create further. During these years, I discovered that the creation of electrical machinery wasn't my true calling.
            I discovered that I enjoyed doing IT work and make the lifes of people better and more efficient.
            I have always been dreaming of starting my own company. The moment is there to finally do just that.
            <br/>
            With my business, I would like to make customized websites for my clients, mainly focussed at smaller companies who are looking
            for their first (or second) steps in digitalization. Because the websites are being coded and not made via a pre-defined framework,
            everything is possible! From a webshop, to different animations or authorization such that you can create an account on the site, everything is possible!
            The website can be combined with databases, in which the data is stored that you like.
            <br/>
            Does this sound a little bit technical? Let me free you with some examples under the portfolio section underneath!
     


 
            import "./intro.css";

const Intro = () => {
  return (
    <div className="i">
      <div className="i-left">
        <div className="i-left-wrapper">
          <h2 className="i-intro">Welcome to this website, my name is</h2>
          <h1 className="i-name">Quinten Vermoesen</h1>
          <div className="i-title">
            <div className="i-title-wrapper">
              <div className="i-title-item">Loves to create</div>
              <div className="i-title-item">Electrical Engineer</div>
              <div className="i-title-item">Data Engineer</div>
              <div className="i-title-item">Full stack web enthusiastic</div>
            </div>
          </div>
          <p className="i-desc">
            <p className="bottom-one">
            Creating things. That's what I have always been passionate about. From drawing to knitting and making small, little wooden "machinery" as a little kid, it were all hobbies of mine.
            Growing up, my fascination for cooking and the broad world of computers florished. This inspired me to study Electrical Egineering, with a Master in Communication and information technology.
            Why? Because I wanted to create further. During these years, I discovered that the creation of electrical machinery wasn't my true calling.
            I discovered that I enjoyed doing IT work and make the lives of people better and more efficient.
            I have always been dreaming of starting my own company. The moment is there to finally do just that.
            </p>
            <p className="bottom-one">
            With my business, I would like to make customized websites for my clients, mainly focused at smaller companies who are looking
            for their first (or second) steps in digitalization. Because the websites are being coded and not made via a pre-defined framework,
            everything is possible! From a webshop, to different animations or authorization such that you can create an account on the site, you name it!
            The website can be combined with databases, in which the data is stored that you like.
            </p>
            <p className="bottom-one">
            Does this sound a little bit technical? Let me relieve you with some examples under the portfolio section underneath!
            </p>
            </p>
        </div>

      </div>
      <div className="i-right">
        <div className="i-bg"></div>
        <img src='https://www.dropbox.com/s/4btlx6wr01vro13/quinten3_compress.png?raw=1' alt="Quinten Vermoesen" className="i-img" />
      </div>
    </div>
  );
};

export default Intro;
        
           
version 15/06/2022:

import "./intro.css";
const Intro = ({introText, nameText, itema, itemb, itemc, itemd, iteme}) => {
  return (
    <div className="i">
      <div className="i-left">
        <div className="i-left-wrapper">
          <h2 className="i-intro">{introText}</h2>
          <h1 className="i-name">{nameText}</h1>
          <div className="i-title">
            <div className="i-title-wrapper">
              <div className="i-title-item">{itema}</div>
              <div className="i-title-item">{itemb}</div>
              <div className="i-title-item">{itemc}</div>
              <div className="i-title-item">{itemd}</div>
              <div className="i-title-item">{iteme}</div>
            </div>
          </div>

          
        </div>

      </div>
    </div>
  );
};

export default Intro; */