import React, {useState, useEffect} from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SidebarBtnWrap, SidebarRoute, SidebarTranslator } from './SidebarElements';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

const Sidebar = ({isOpen, langToggle, toggle, isToggled}) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, []);

    return (
        <SidebarContainer scrollNav={scrollNav} isOpen={isOpen} >
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink 
                to='about' 
                onClick={toggle}
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}>{isToggled?'Get to know me':'Leer me kennen'}</SidebarLink>

                <SidebarLink 
                to='services' 
                onClick={toggle}
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}>{isToggled?'What I offer':'Wat ik aanbied'}</SidebarLink>

                <SidebarLink 
                to='portfolio' 
                onClick={toggle}
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}>{isToggled?'Portfolio':'Portfolio'}</SidebarLink>

                <SidebarLink 
                to='contact' 
                onClick={toggle}
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}>{isToggled?'Contact':'Contact'}</SidebarLink>
            </SidebarMenu>

            <SidebarBtnWrap>
                <SidebarRoute to='contact'
                 onClick={toggle}
                 smooth={true}
                 duration={500}
                 spy={true}
                 exact='true'
                 offset={-80}>
                    {isToggled?'Build me a website!':'Bouw me een website'}
                </SidebarRoute>
            </SidebarBtnWrap>
            
            <SidebarBtnWrap>
                <SidebarTranslator>
                    <ToggleSwitch langToggle={langToggle} />
                </SidebarTranslator>
            </SidebarBtnWrap>

        </SidebarWrapper>

        </SidebarContainer>
    )
}

export default Sidebar;

/* 
            <SidebarBtnWrap>
                <ToggleSwitch langToggle={langToggle}/>
            </SidebarBtnWrap> */
