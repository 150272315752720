export const homeObjThree = {
    id: 'portfolio',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'What have I made?',
    headline: 'You are not our first customer, but certainly our most important one!',
    description: 'Curious about what is possible? Or just scrolling through to get some inspiration? Have a look at the previous projects I made!',
    buttonLabel: 'View projects',
    imgStart: false,
    slider: true,
    /*img: require('../../images/svg-3.svg').default,*/
    alt: 'person looking up',
    dark: false,
    primary: false,
    darkText: true
};