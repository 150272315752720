export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLineEN: 'Who am I?',
    topLineNL: 'Wie ben ik?',
    headlineEN: 'Electrical Engineer with a passion for web and digital automation',
    headlineNL: 'Burgerlijk ingenieur met een passie voor web en digitale automatisatie',
    descriptionEN: '"As an Electrical Engineer, I learned to think analytically with a focus on problem solving. As a foodie and drawer, I discovered my passion for the creative proces of creating things. Now, I work full time as a Data Engineer and I apply this combination of skills to my secondary occupation in which I make custom websites and webapps with special features to make companies more efficient!" ',
    descriptionNL: '"Als burgerlijk ingenieur elektrotechniek heb ik geleerd om analytisch en probleemoplossend te denken. Als foodie en tekenaar ontdekte ik mijn passie voor het creatief proces van dingen te creëren. Nu werk ik voltijds als data ingenieur en deze volledige skillset pas ik nu toe om in bijberoep op maat gemaakte websites en webapps te maken, met speciale functionaliteiten om bedrijven efficiënter te maken!"',
    buttonLabelEN: 'Read more',
    buttonLabelNL: 'Lees meer',
    imgStart: true,
    slider: false,
    img: require('../../images/cut_quinten.png').default,
    /*img: 'https://ucac46bcec784c9c9b68e0f168c2.previews.dropboxusercontent.com/p/thumb/ABiYBlwZoXkHK1QfDNyokQLxMINtf6dFRxPzahotoKBewZtEx4dCx2xM7YPWm7tXBAjbj0-ag4WR0XW83hAyE11E8BW-G87E5zBzzVQ74G4WcfbB_ItvrFUZJm2NZ1q2WHT10bCVTMmRlN-oO5ngcYNbpRyrVAZe6pdsVA6z9ncy96F3HPwXef6gPmnUjQFp76FKomZmo9SoNo0LLpGeq-CH0NP5Vuljf_GfMMKuH1p4kl3TxCfQyYpr1OXIwGv6gZKRgiKfvvgHcPJXdiCS2dyPTn-tNft-I5Ql9obclaFuggTg-2axyrCWyVeYUhg4VgJQFOi7y7iA_geS0i-OlbRhg249foQpecOAZqGKpMAx24oUZKyg_1vU8Cm2nQe8koCRvU9eEJn4nYXekZhYIrJHM3rjeToAPSpDPTi1Mv5YBg/p.png',*/
    /*img: 'https://www.dropbox.com/s/5d8ca1bsc6r9g7q/svg-2.93705893.svg?raw=1',*/
    alt: 'civil engineer',
    dark: false,
    primary: false,
    darkText: true
};





